export default [

    {
      path: '/karyawan',
      name: 'karyawan',
      component: () => import('@/views/bsdproperti/karyawan/List.vue'),
      meta: {
        pageTitle: 'Karyawan',
        breadcrumb: [
          {
            text: 'Karyawan',
            active: true,
          },
        ],
        action: 'manage',
        resource: 'employeeSubject',
      },
    },
    {
      path: '/karyawan/form',
      name: 'karyawan-form-tambah',
      component: () => import('@/views/bsdproperti/karyawan/Form.vue'),
      meta: {
        pageTitle: 'Form Karyawan',
        breadcrumb: [
          {
            text: 'Karyawan',
            to: '/karyawan',
          },
          {
            text: 'Form Karyawan',
            active: true,
          },
        ],
        action: 'manage',
        resource: 'employeeSubject',
      },
    },
    {
      path: '/karyawan/form/:id',
      name: 'karyawan-form-edit',
      component: () => import('@/views/bsdproperti/karyawan/Form.vue'),
      meta: {
        pageTitle: 'Form Karyawan',
        breadcrumb: [
          {
            text: 'Form Karyawan',
            active: true,
          },
        ],
        action: 'manage',
        resource: 'employeeSubject',
      },
    },
    {
      path: '/karyawan/form/:id/detail',
      name: 'karyawan-form-detail',
      component: () => import('@/views/bsdproperti/karyawan/Detail.vue'),
      meta: {
        pageTitle: 'Detail Karyawan',
        breadcrumb: [
          {
            text: 'Detail Karyawan',
            active: true,
          },
        ],
        action: 'manage',
        resource: 'employeeSubject',
      },
    },
  ]
  