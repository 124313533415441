export default [

    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/bsdproperti/user/List.vue'),
        meta: {
            pageTitle: 'User',
            breadcrumb: [
                {
                    text: 'User',
                    active: true,
                },
            ],
            action: 'manage',
            resource: 'userSubject',
        },
    },
]
