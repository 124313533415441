export default [

  {
    path: '/kepemilikan',
    name: 'kepemilikan',
    component: () => import('@/views/bsdproperti/kepemilikan/List.vue'),
    meta: {
      pageTitle: 'Kepemilikan',
      breadcrumb: [
        {
          text: 'Kepemilikan',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
  {
    path: '/kepemilikan/form',
    name: 'kepemilikan-form-tambah',
    component: () => import('@/views/bsdproperti/kepemilikan/Form.vue'),
    meta: {
      pageTitle: 'Form Kepemilikan',
      breadcrumb: [
        {
          text: 'Kepemilikan',
          to: '/kepemilikan',
        },
        {
          text: 'Form Kepemilikan',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
  {
    path: '/kepemilikan/form/:id',
    name: 'kepemilikan-form-edit',
    component: () => import('@/views/bsdproperti/kepemilikan/Form.vue'),
    meta: {
      pageTitle: 'Form Kepemilikan',
      breadcrumb: [
        {
          text: 'Form Kepemilikan',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
  {
    path: '/kepemilikan/form/:id/detail',
    name: 'kepemilikan-form-detail',
    component: () => import('@/views/bsdproperti/kepemilikan/Detail.vue'),
    meta: {
      pageTitle: 'Detail Kepemilikan',
      breadcrumb: [
        {
          text: 'Detail Kepemilikan',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
]
