export default [

    {
        path: '/penggajian',
        name: 'penggajian',
        component: () => import('@/views/bsdproperti/penggajian/List.vue'),
        meta: {
            pageTitle: 'Penggajian',
            breadcrumb: [
                {
                    text: 'Penggajian',
                    active: true,
                },
            ],
            action: 'manage',
            resource: 'employeeSubject',
        },
    },
]
