export default [

    {
        path: '/pengeluaran',
        name: 'pengeluaran',
        action: 'manage',
        resource: 'transactionExpensesSubject',
        component: () => import('@/views/bsdproperti/pengeluaran/List.vue'),
        meta: {
            pageTitle: 'Pengeluaran',
            breadcrumb: [
                {
                    text: 'Pengeluaran',
                    active: true,
                },
            ],
            action: 'manage',
            resource: 'transactionExpensesSubject',
        },
    },
]
