export default [

    {
        path: '/legal',
        name: 'legal',
        component: () => import('@/views/bsdproperti/legal/List.vue'),
        meta: {
            pageTitle: 'Legal',
            breadcrumb: [
                {
                    text: 'Legal',
                    active: true,
                },
            ],
            action: 'manage',
            resource: 'legalSubject',
        },
    },
]
