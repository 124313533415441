export default [

  {
    path: '/aset',
    name: 'aset',
    component: () => import('@/views/bsdproperti/aset/List.vue'),
    meta: {
      pageTitle: 'Aset',
      breadcrumb: [
        {
          text: 'Aset',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'assetSubject',
    },
  },
  {
    path: '/aset/form',
    name: 'aset-form-tambah',
    component: () => import('@/views/bsdproperti/aset/Form.vue'),
    meta: {
      pageTitle: 'Form Aset',
      breadcrumb: [
        {
          text: 'Aset',
          to: '/aset',
        },
        {
          text: 'Form Aset',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'assetSubject',
    },
  },
  {
    path: '/aset/form/:id',
    name: 'aset-form-edit',
    component: () => import('@/views/bsdproperti/aset/Form.vue'),
    meta: {
      pageTitle: 'Form Aset',
      breadcrumb: [
        {
          text: 'Form Aset',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'assetSubject',
    },
  },
  {
    path: '/aset/form/:id/detail',
    name: 'aset-form-detail',
    component: () => import('@/views/bsdproperti/aset/Detail.vue'),
    meta: {
      pageTitle: 'Detail Aset',
      breadcrumb: [
        {
          text: 'Detail Aset',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'assetSubject',
    },
  },
]
