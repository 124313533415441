export default [

    {
        path: '/penagihan',
        name: 'penagihan',
        component: () => import('@/views/bsdproperti/penagihan/List.vue'),
        meta: {
            pageTitle: 'Penagihan',
            breadcrumb: [
                {
                    text: 'Penagihan',
                    active: true,
                },
            ],
            action: 'manage',
            resource: 'customerSubject',
        },
    },
]
