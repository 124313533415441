import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'

// bsdproperti
import dashboard from './bsdproperti/dashboard'
import notifikasi from './bsdproperti/notifikasi'

// customer
import kavling from './bsdproperti/kavling'
import blok from './bsdproperti/blok'
import kepemilikan from './bsdproperti/kepemilikan'
import penagihan from './bsdproperti/penagihan'
import ppjb from './bsdproperti/ppjb'

// aset
import aset from './bsdproperti/aset'

// employee
import karyawan from './bsdproperti/dataKaryawan'
import penggajian from './bsdproperti/dataPenggajian'

// transaksi
import pemasukan from './bsdproperti/pemasukan'
import pengeluaran from './bsdproperti/pengeluaran'

// legalitas
import legal from './bsdproperti/legal'

// user
import user from './bsdproperti/user'

// template & auth
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'notifikasi' } },

    // bsdproperti
    ...dashboard,
    ...notifikasi,

    // customer
    ...kavling,
    ...blok,
    ...kepemilikan,
    ...penagihan,
    ...ppjb,

    // aset
    ...aset,

    // employee
    ...karyawan,
    ...penggajian,

    // transaksi
    ...pemasukan,
    ...pengeluaran,

    // legalitas
    ...legal,

    // user
    ...user,

    // template & auth
    ...apps,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in

  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
