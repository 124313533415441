export default [

  {
    path: '/notifikasi',
    name: 'notifikasi',
    component: () => import('@/views/bsdproperti/notifikasi/List.vue'),
    meta: {
      pageTitle: 'Notifikasi',
      breadcrumb: [
        {
          text: 'Notifikasi',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'notificationSubject',
    },
  },
]
