export default [

  {
    path: '/ppjb',
    name: 'ppjb',
    component: () => import('@/views/bsdproperti/ppjb/List.vue'),
    meta: {
      pageTitle: 'PPJB',
      breadcrumb: [
        {
          text: 'PPJB',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
]
