export default [

  {
    path: '/kavling',
    name: 'kavling',
    component: () => import('@/views/bsdproperti/kavling/List.vue'),
    meta: {
      pageTitle: 'Kavling',
      breadcrumb: [
        {
          text: 'Kavling',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'customerSubject',
    },
  },
]
