export default [

    {
      path: '/pemasukan',
      name: 'pemasukan',
      action: 'manage',
      resource: 'transactionIncomeSubject',
      component: () => import('@/views/bsdproperti/pemasukan/List.vue'),
      meta: {
        pageTitle: 'Pemasukan',
        breadcrumb: [
          {
            text: 'Pemasukan',
            active: true,
          },
        ],
        action: 'manage',
        resource: 'transactionIncomeSubject',
      },
    },
  ]
  